import { FaSearch } from 'react-icons/fa';
import { Box, css, Flex, Link } from 'theme-ui';
import theme from '../../../lib/theme';
import styled from '../../../lib/transient-styled';
import { TextButton } from '../../../shared-styled/blocks/buttons';
import pxToRem from '../../../utils/pixelsToRem';
import RawHtml from '../../RawHtml';

const domain = process.env.NEXT_PUBLIC_HOST_ENV
  ? `https://${process.env.NEXT_PUBLIC_HOST_ENV}`
  : 'https://dev.mp.quintagroup.com';

export const HeroBlockContainer = styled(Flex)(
  css({
    justifyContent: 'center',
    flexDirection: ['column', 'column', 'row'],
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    boxShadow: 'inset 0 0 0 1000px rgba(5, 21, 36, 0.85)',
    color: 'white',

    [`@media (max-width: ${theme.breakpoints[1]})`]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  }),
  ({ $background, $isLogged }) =>
    css({
      backgroundImage: `url(${
        domain + $background.path ? $background.path : ''
      })`,
      height: $isLogged ? ['100%', '100%', '690px'] : ['100%', '100%', '690px'],
    }),
);

export const TrustedSourceColumn = styled(Flex)(
  css({
    flexDirection: 'column',
    gap: '70px',
    margin: ['56px 52px 75px 52px', '135px 100px 0'],

    [`@media (max-width: ${theme.breakpoints[1]})`]: {
      alignItems: 'center',
      gap: '48px',

      margin: '56px 52px',
    },
  }),
);

export const TrustedSourceTitle = styled.h3(
  css({
    ml: [0, '-64px'],
    fontFamily: 'Urbanist',
    fontWeight: '300',
    fontSize: pxToRem(48),
    lineHeight: pxToRem(52),
    letterSpacing: '0.03em',
  }),
);

export const TrustedSourceDescription = styled(RawHtml)(
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    ml: ['0', '0', '-30px'],
    pl: '30px',
    borderLeft: '1px solid white',

    '& p': {
      fontWeight: '400',
      fontSize: pxToRem(24),
      lineHeight: pxToRem(34),
      letterSpacing: '0.05em',
    },
  }),
);

export const TextButtonWrapper = styled('div')(
  css({
    margin: ['0 auto', '0 auto', '0'],
  }),
);

export const AlignedTextButton = styled(TextButton)(
  css({
    px: '26px',
    py: '18px',
  }),
);

// Whats New

export const WhatsNewColumn = styled(Box)(
  css({
    width: ['100%', '100%', '280px'],
    px: ['62px', '80px', '40px'],
    pb: ['38px', '48px'],
    background: 'rgba(43, 121, 138, 0.5)',
    backdropFilter: 'blur(4px)',
  }),
);

export const WhatsNewColumnTitle = styled.h3(
  css({
    my: '40px',
    px: '12px',
    fontFamily: '"Urbanist"',
    fontWeight: '500',
    fontSize: pxToRem(24),
    letterSpacing: '0.01em',
    borderLeft: '4px white solid',
  }),
);

export const ColumnContentImage = styled.img(
  css({
    objectPosition: '50% 50%',
    objectFit: 'contain',
    width: '100%',
    maxHeight: ['150px', '220px', '120px'],
    padding: '5px 0',
  }),
);

export const ColumnContentTitle = styled.p(
  css({
    mb: '24px',
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    letterSpacing: '0.01em',
    color: 'white',
    cursor: 'pointer',
  }),
);

// Logged

export const SearchContainer = styled(Flex)(
  css({
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  }),
);

export const HeroBlockSearchBar = styled(Flex)(
  css({
    flexDirection: 'column',
    gap: '7px',
    p: ['0', '0', '7px'],
    background: ['none', 'none', 'whiteDarker'],
  }),
);

export const RelativeContainer = styled.div(
  css({
    width: '100%',
    position: 'relative',
    height: ['190px', '200px', 'auto'],
    display: ['flex', 'flex', 'auto'],
    alignItems: 'center',
    padding: ['70px 20px 30px 20px', '70px 20px 30px 20px', '0'],
  }),
);

export const Search = styled.input(
  css({
    width: '100%',
    padding: '20px 10px 20px 55px',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
    outline: 'none',

    '&::placeholder': {
      fontWeight: '500',
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0.05em',
      color: 'grayDarker',
    },
  }),
);

export const WeaponsSection = styled(Flex)(
  css({
    gap: '6px',
    flexWrap: 'wrap',
    background: 'white',
    p: ['20px', '20px', 0],
  }),
);

export const WeaponLink = styled(Link)(
  css({
    flexGrow: '1',

    '&:hover': {
      textDecoration: 'none',
    },
  }),
);

export const WeaponBlock = styled.div(
  css({
    padding: ['20px 32px', '20px 40px', '20px'],
    background: 'darkGreen',
    backdropFilter: 'blur(20px)',

    fontFamily: "'Roboto'",
    fontWeight: '700',
    color: 'white',
    lineHeight: '28px',
    letterSpacing: '0.025em',
    textTransform: 'uppercase',
    textAlign: 'center',

    '&:hover': {
      background: 'focus',
      color: 'text',
    },
  }),
);

export const SearchIcon = styled(FaSearch)(
  css({
    position: 'absolute',
    top: ['107px', '107px', '20px'],
    left: ['40px', '40px', '20px'],
    width: '17px',
    color: 'classyGrey',
  }),
);
